import React from 'react';
import Section from '../../components/section';
import Layout from '../../components/layout';
import imagePersonalization from '../../images/roadmap/image-personalization.png';
import unifiedInbox from '../../images/roadmap/unified-inbox.png';
import meetingScheduling from '../../images/roadmap/meeting-scheduling.png';
import inboxHealth from '../../images/roadmap/inbox-health.png';
import webhooks from '../../images/roadmap/webhooks.png';
import integrations from '../../images/roadmap/integrations.png';
import {doneIcon} from '../../images/svgIcons';
import './styles.scss';

const Roadmap = () => {
  const arr = [
    {
      image: imagePersonalization,
      title: 'Image personalization',
      description: 'Spark conversations and get replies with highly personalized emails that scale.',
      done: true,
    },
    {
      image: unifiedInbox,
      title: 'Unified inbox',
      description: 'Stay on top of all your replies regardless of how many email accounts you use.',
      done: true,
    },
    {
      image: inboxHealth,
      title: 'Inbox health',
      description:
        'Increase your open rates by optimizing your email reputation on autopilot with DMARC, DKIM, SPF.',
      done: true,
    },
    {
      image: meetingScheduling,
      title: 'Meeting scheduling',
      description: 'Eliminate the hassle of back-and-forth emails to arrange meetings with built-in meeting scheduling',
      done: false,
    },    
    {
      image: webhooks,
      title: 'Webhooks',
      description: 'Connect Hexospark programmatically.',
      done: false,
    },
    {
      image: integrations,
      title: 'Connect to 3rd party integration platforms',
      description: 'Connect Hexospark to 1000’s of 3rd party apps.',
      done: false,
    },
  ];

  return (
    <Layout>
      <Section light pagehero lgPd>
        <h1 className="f_size_48 f_600 t_color4 l_height54 text-center">Hexospark Roadmap</h1>
        <p className="f_300 page-hero-subTitle mb-0 pricing-p-hero text-center">
          At Hexospark, our internal roadmap is focused on helping you find more leads, get better replies, and grow
          your revenue on autopilot.
        </p>
        <p className="f_300 page-hero-subTitle mb-0 pricing-p-hero text-center pt-3 pb-5">
          Follow our progress via our <a href="https://hexospark.com/academy/changelog">changelog</a>.
        </p>
        <h2 className="f_size_30 f_600 t_color4 l_height54 text-center">Here is a teaser of some of our plans:</h2>
        <div className="row">
          {arr.map(item => (
            <div className="col-12 col-lg-6 col-xl-4 p-2 roadmap-card mx-auto" key={item.title}>
              <div className='roadmap-image-block'>
                <img src={item.image} width={340} height={190} alt={item.title}/>
              </div>
              <div className='roadmap-title'>
                {item.title} {item.done ? doneIcon : null}
              </div>
              <div className='roadmap-description'>{item.description}</div>
            </div>
          ))}
        </div>
      </Section>
    </Layout>
  );
};

export default Roadmap;
